
import mixins from "vue-typed-mixins";
import { mapGetters, mapMutations } from "vuex";
import { BCollapse, BNavbar, BNavbarToggle, BNavItem, BNavbarNav, BContainer, BModal, BNavItemDropdown, BDropdownItem } from "bootstrap-vue";
import { StyleValue } from "vue/types/jsx";
import store from "./store";
import router from "./router";
import Common from "./mixins/Common";
import { IReCaptchaVue } from "./interfaces/IReCaptchaVue";
import ShoppingCartComponent from "./components/ShoppingCartComponent.vue";
import AccountSettingsVueComponentVue from "./components/AccountSettingsVueComponent.vue";
import { ShoppingCartViewModel } from "./interfaces/ShoppingCartViewModel";
import swal from "sweetalert";
import { AccountSettingsViewModel } from "./interfaces/AccountSettingsViewModel";
import SidebarMenu from "./components/SidebarMenuVueComponent.vue";
import PatientProfileVueComponent from "@/components/PatientProfileVueComponent.vue";
import VueGtm from "@gtm-support/vue2-gtm";
import Vue from "vue";
import Avatar from "primevue/avatar";

export default mixins(Common).extend({
  mixins: [Common],
  name: "App",
  data() {
    return {
      siteSetupHasBeenCalled: false,
      clarityLoaded: false,
      maintenanceHTML: "",
      displayMaintenanceScreen: true,
      innerMaintenanceInterval: 0,
      outerMaintenanceInterval: 0,
      isPopular: true,
      isMobile: false,
      hideHeader: false,
      isDropFrameVisible: false,
      accountSettings: null as AccountSettingsViewModel | null,
      ppVM: {
        onCancelCB: () => {
          this.$bvModal.hide("modal-pp");
        },
        onSavedCB: () => {
          this.$bvModal.hide("modal-pp");
        },
      },
    };
  },
  async beforeCreate() {
    window.history.scrollRestoration = "manual";
    await store.dispatch("initialiseStore");
    document.body.scrollTop = 0;
  },
  // async beforeMount() {
  //   await store.dispatch("GetMaintenanceIntervals");
  //   this.displayMaintenanceScreen = await this.getMaintenanceStatus();
  //   if (this.displayMaintenanceScreen && this.$route.path !== "/maintenance/") {
  //     if ((this.$route.path !== "/signin" && this.Role !== "Admin") || (this.$route.path !== "/signin" && this.Role !== "AffiliateAdmin")) {
  //       this.$router.push("/maintenance/");
  //     }
  //   }
  //   if (!this.displayMaintenanceScreen && this.PreviousMaintenanceResult) {
  //     store.commit("setPreviousMaintenanceResult", false);
  //     if ((this.$route.path !== "/signin" && this.Role !== "Admin") || (this.$route.path !== "/signin" && this.Role !== "AffiliateAdmin")) {
  //       this.$router.push(this.PreviousScreen);
  //     }
  //   }
  // },
  async mounted() {
    this.setLoading(true);
    this.updateFavicon("Content.siteIcon");
    this.getUTM();
    this.getPromoCode();
    // Get theme and content for site
    await store.dispatch("getSiteSetup");
    if (this.Content.GTMConfig) {
      var gtm = Object.assign({}, this.Content.GTMConfig); // Make a copy so the router doesn't get inserted into state
      gtm.vueRouter = router;
      Vue.use(VueGtm, gtm);
      //e.g.
      /*{
        id: "GTM-XXXXXX", // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
        queryParams: {
          // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
          gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
          gtm_preview: "env-4",
          gtm_cookies_win: "x",
        },
        defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
        compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
        nonce: null, // Will add `nonce` to the script tag
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: true, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        ignoredViews: [], // Don't trigger events for specified router names (optional)
        trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
      }*/
    }
    if (this.Affiliate.isAPIOnly) {
      router.push("/apionly");
      const a = document.getElementById("app") as HTMLElement;
      a.classList.remove("d-none");
      return;
    }
    await this.loadCollect();
    window.setTimeout(async () => {
      await (this as any as IReCaptchaVue).$recaptchaLoaded();
      (this as any as IReCaptchaVue).$recaptchaInstance.hideBadge();
    }, 1000);
    await this.setupSite();
    await this.setupOTCCart();
    var hash = this.$router.currentRoute.hash.replace("#", "");
    if (hash) this.$nextTick(() => this.scrollFix(this.$router.currentRoute.fullPath, true));
    this.siteSetupHasBeenCalled = true;
    this.setLoading(false);
    // setTimeout(() => {
    //   this.MonitorMaintenanceStatus();
    // }, this.NotWithinMaintenanceWindow);
    if (this.$router.currentRoute.path === "/questionnaire/getstarted" && !this.clarityLoaded) {
      this.loadMicrosoftClarity();
    }
    this.$router.beforeEach((to, from, next) => {
      if (to.path === "/questionnaire/getstarted" && !this.clarityLoaded) {
        this.loadMicrosoftClarity();
      } else if (this.clarityLoaded && to.path !== "/questionnaire/getstarted") {
        this.stopMicrosoftClarity();
      }
      next();
    });
  },
  computed: {
    ...mapGetters({
      Affiliate: "getAffiliate",
      Theme: "getTheme",
      Content: "getContent",
      Loading: "getLoading",
      SignedIn: "getSignedIn",
      Role: "getRole",
      ShoppingCart: "getShoppingCart",
      IsCollectJSLoaded: "getIsCollectJSLoaded",
      PhotoPath: "getPhotoPath",
      FirstName: "getFirstName",
      LastName: "getLastName",
      UTM: "getUTM",
      ShowCart: "getShowCart",
      ShowSidebar: "getShowSidebarAdmin",
      ShowMainMenu: "getShowMainMenu",
      PreviousMaintenanceResult: "getPreviousMaintenanceResult",
      PreviousScreen: "getPreviousScreen",
      ExpectedMaintenanceBool: "getExpectedMaintenanceBool",
      ExpectedMaintenanceStart: "getExpectedMaintenanceStart",
      NotWithinMaintenanceWindow: "getNotWithinMaintenanceWindow",
      WithinMaintenanceWindow: "getWithinMaintenanceWindow",
    }),
    SiteLogoStyle(): StyleValue {
      if (this.Theme.SiteLogoStyle) return this.Theme.SiteLogoStyle;
      else
        return {
          "max-height": "65px",
        };
    },
    shoppingCartStyle(): StyleValue {
      return {
        display: "block",
        //display: this.ShoppingCart && this.ShoppingCart.visible ? "block" : "none",
      };
    },
    routerViewStyle(): StyleValue {
      return {
        display: "block",
      };
    },
    currentYear(): string {
      return new Date().getFullYear().toString();
    },
  },
  watch: {
    async Loading(newVal) {
      if (newVal) {
        window.setTimeout(async () => {
          await this.$nextTick();
          this.$bvModal.show("modal-loading");
        }, 10);
      } else
        window.setTimeout(async () => {
          await this.$nextTick();
          this.$bvModal.hide("modal-loading");
        }, 200);
    },
    async $route(nroute) {
      const cart = this.ShoppingCart;

      if (router.currentRoute.name == "cart" || router.currentRoute.path.startsWith("/checkout") || this.ShoppingCart?.items?.length <= 0) {
        if (cart) {
          this.setShowCart(false);
          cart.visible = false;
        }
      } else {
        if (cart && this.ShoppingCart?.items?.length >= 0) {
          this.setShowCart(true);
          cart.visible = true;
        }
      }
      store.commit("setShoppingCart", cart);
    },
    async Role(nv) {
      await this.submitUTM();

      if (nv === "Patient") {
        this.UpdateDeviceTouch();
      }
    },
  },
  methods: {
    ...mapMutations({
      SetIsCollectJSLoaded: "setIsCollectJSLoaded",
      setLoading: "setLoading",
      setShowCart: "setShowCart",
      setSidebarAdmin: "setSidebarAdmin",
      setShowMainMenu: "setShowMainMenu",
      setExpectedMaintenanceBool: "setExpectedMaintenanceBool",
      setExpectedMaintenanceStart: "setExpectedMaintenanceStart",
    }),
    // async MonitorMaintenanceStatus() {
    //   this.displayMaintenanceScreen = await this.getMaintenanceStatus();

    //   if (!this.displayMaintenanceScreen && this.PreviousMaintenanceResult) {
    //     store.commit("setPreviousMaintenanceResult", false);
    //     if (this.Role !== "Admin" || this.Role !== "AffiliateAdmin") {
    //       this.$router.push(this.PreviousScreen);
    //       window.location.reload();
    //     }
    //   }

    //   if (this.displayMaintenanceScreen && this.$route.path !== "/maintenance/" && this.Role !== "Admin") {
    //     if ((this.$route.path !== "/signin" && this.Role !== "Admin") || (this.$route.path !== "/signin" && this.Role !== "AffiliateAdmin")) {
    //       this.$router.push("/maintenance/");
    //     }
    //   }

    //   if (this.ExpectedMaintenanceBool || this.displayMaintenanceScreen)
    //     setTimeout(() => {
    //       this.MonitorMaintenanceStatus();
    //     }, this.WithinMaintenanceWindow);
    //   else
    //     setTimeout(() => {
    //       this.MonitorMaintenanceStatus();
    //     }, this.NotWithinMaintenanceWindow);
    // },
    // withinMaintenanceWindow() {
    //   const emsDateHolder = new Date(this.ExpectedMaintenanceStart);
    //   const currentTime = new Date();
    //   const timeDifference = Math.abs(emsDateHolder.getTime() - currentTime.getTime());

    //   if (timeDifference <= 900000) {
    //     this.setExpectedMaintenanceBool(true);
    //   }
    // },
    // async getMaintenanceStatus() {
    //   /*
    //   Workflow, if holder.success is false, then that means there is no active maintenance.
    //   if holder.success is true, then we display the data.
    //   */
    //   const holder = await store.dispatch("GetMaintenanceStatus");
    //   if (holder && holder !== "") {
    //     store.commit("setMaintenanceDetails", holder.data);
    //     store.commit("setScheduledMaintenance", holder.errors[0]);

    //     if (holder.success) {
    //       store.commit("setPreviousMaintenanceResult", true);
    //     }

    //     if (this.$route.path !== "/maintenance/") {
    //       store.commit("setPreviousScreen", this.$route.path);
    //     } else {
    //       store.commit("setPreviousScreen", "/");
    //     }

    //     if (holder.errors[0] !== "") {
    //       this.setExpectedMaintenanceStart(holder.errors[0]);
    //       this.withinMaintenanceWindow();
    //     }

    //     this.displayMaintenanceScreen = holder.success;

    //     if ((!this.displayMaintenanceScreen && this.PreviousMaintenanceResult && this.Role !== "Admin") || (!this.displayMaintenanceScreen && this.PreviousMaintenanceResult && this.Role !== "AffiliateAdmin")) {
    //       this.$router.push(this.PreviousScreen);
    //     }

    //     if (this.$route.path !== "/maintenance/" && this.$route.path !== "/signin/") {
    //       if (this.displayMaintenanceScreen) {
    //         if (this.Role !== "Admin" && this.Role !== "AffiliateAdmin") {
    //           this.$bvToast.toast("Trouble receiving data, this website is currently undergoing maintenance, please check back in later!", { title: "Maintenance", variant: "warning", autoHideDelay: 2000, appendToast: true });
    //         }
    //       }
    //     }

    //     return this.displayMaintenanceScreen;
    //   } else {
    //     this.displayMaintenanceScreen = true;
    //     if (this.$route.path !== "/maintenance/") {
    //       store.commit("setPreviousScreen", this.$route.path);
    //     } else {
    //       store.commit("setPreviousScreen", "/");
    //     }
    //     if (this.Content.MaintenanceModeContent !== "") {
    //       store.commit("setMaintenanceDetails", this.Content.MaintenanceModeContent);
    //     } else {
    //       const defaultHtmlHolder = await store.dispatch("GetDefaultMaintenanceHTML");
    //       store.commit("setMaintenanceDetails", defaultHtmlHolder);
    //     }
    //     store.commit("setScheduledMaintenance", "Unscheduled maintenance, please check back soon.");

    //     this.setExpectedMaintenanceStart("Unscheduled maintenance, please check back soon.");
    //     this.withinMaintenanceWindow();

    //     if (this.$route.path !== "/maintenance/" && this.$route.path !== "/signin/") {
    //       if (this.displayMaintenanceScreen) {
    //         if (this.Role !== "Admin" && this.Role !== "AffiliateAdmin") {
    //           this.$bvToast.toast("Trouble receiving data, this website is currently undergoing maintenance, please check back in later!", { title: "Maintenance", variant: "warning", autoHideDelay: 2000, appendToast: true });
    //         }
    //       }
    //     }
    //     return true;
    //   }
    // },
    toggleDropFrame() {
      this.isDropFrameVisible = !this.isDropFrameVisible;
    },
    loadMicrosoftClarity() {
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", this.Content.ClarityID);

      this.clarityLoaded = true;
    },
    stopMicrosoftClarity() {
      if (window.clarity) {
        window.clarity("stop");
        console.log("Clarity tracking stopped");
      }
    },
    goToNavLink(nl: any) {
      if (nl.isHash) {
        this.scrollFix(nl.link);
      } else {
        document.location.href = nl.link;
      }
      this.setShowMainMenu(false);
    },
    scrollFix(path: string, scrollOnly?: boolean) {
      window.scrollTo(0, 0);
      if (!scrollOnly && router.currentRoute.fullPath != path) router.push(path);
      setTimeout(() => {
        document.getElementById(path.substring(path.indexOf("#") + 1))?.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 300);
    },
    async signOut() {
      store.commit("setShoppingCart", {} as ShoppingCartViewModel);
      await store.dispatch("SignOut");
      this.setShowMainMenu(false);
      router.push("/signin");
    },
    async setupSite() {
      // Set site icon
      const f = document.getElementById("siteIcon") as HTMLElement;
      if (this.Content.SiteIcon) f.setAttribute("href", this.Content.SiteIcon);

      // Set theme colors
      const r = document.querySelector(":root") as HTMLElement;
      for (const c of this.Theme.Colors) {
        r.style.setProperty(c.Key, c.Value);
      }
      // Set font
      const a = document.getElementById("app") as HTMLElement;
      a.style.setProperty("font-family", this.Theme.FontFace);

      const st = document.getElementById("siteTitle") as HTMLElement;
      st.innerHTML = this.Content.SiteTitle;

      a.classList.remove("d-none");
    },
    async setupOTCCart() {
      if (this.Role == "Patient") {
        const cart = (await store.dispatch("GetShoppingCart")) as ShoppingCartViewModel;
        if (cart) {
          cart.visible = true;
        }
        store.commit("setShoppingCart", cart);
      }
    },
    async loadCollect() {
      const d = document;
      const s = "script";
      const id = "collectJS";
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.onload = () => {
        // remote script has loaded
        this.SetIsCollectJSLoaded(true);
      };
      var k = this.Affiliate.testMode ? this.Content.CollectJSTestKey : this.Content.CollectJSProdKey;
      js.setAttribute("data-tokenization-key", k);
      (js as HTMLScriptElement).src = "https://secure.networkmerchants.com/token/Collect.js";
      fjs?.parentNode?.insertBefore(js, fjs);

      while (!this.IsCollectJSLoaded) {
        await this.sleep(100);
      }
    },
    async showSettings() {
      this.setLoading(true);
      const settings = await store.dispatch("GetAccountSettings");
      this.setLoading(false);
      if (!settings) {
        swal("Error", "Error getting account settings. Please try again.");
        return;
      }
      this.accountSettings = settings;
      this.$bvModal.show("modal-as");
    },
    mainMenuToggle() {
      this.setShowMainMenu(!this.ShowMainMenu);
    },
    async closeMainMenuAndNavigate(route) {
      this.setLoading(true);
      this.setShowMainMenu(false);

      try {
        await this.$router.push(route);
      } finally {
        this.setLoading(false);
      }
    },
    ShowPatientProfile() {
      this.$bvModal.show("modal-pp");
    },
    openCart() {
      this.setShowCart(true);
    },
    closeCart() {
      this.setShowCart(false);
    },
    toggleCart() {
      if (this.ShowCart) this.closeCart();
      else {
        this.setShowMainMenu(false);
        this.openCart();
      }
    },
    openSidebarAdminMenu() {
      this.setSidebarAdmin(true);
    },
    updateFavicon(newFaviconURL: string) {
      const linkTags = document.querySelectorAll("link[rel*='icon']");
      linkTags.forEach((linkTag) => {
        linkTag.setAttribute("href", newFaviconURL);
      });
    },

    async UpdateDeviceTouch() {
      try {
        // Send a blank request to the backend (no payload needed)
        const response = await this.$store.dispatch("UpdateDeviceTouch");
      } catch (error) {
        console.error("Error in UpdateDeviceTouch:", error);
      }
    },
  },
  components: {
    BCollapse,
    BNavbar,
    BNavbarToggle,
    BNavItem,
    BNavbarNav,
    BContainer,
    BModal,
    ShoppingCartComponent,
    AccountSettingsVueComponentVue,
    BNavItemDropdown,
    BDropdownItem,
    SidebarMenu,
    PatientProfileVueComponent,
    Avatar,
  },
});
